import React from "react";
import { graphql, Link } from "gatsby";
import { Grid, List, Button} from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
  ContactForm,
} from "@fishrmn/fishrmn-components";

import emailCallback from "../utils/donate-email.js";

import Layout from "../components/Layout";
import { getPageHeroImages } from "../utils/images";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Support Our Local Farm</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Support Our Local Farm"}
              tagline={""}
              images={["https://fisherman.gumlet.io/public/opheliasbluevine/Ophelias_Oregano.jpg"]}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={300}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container support-local-farm"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12}>
              <h1>Support Your Local Urban Farm!</h1>
              <p>We're excited to share a heartwarming story about how Ophelia’s Blue Vine Farm has made a difference in our community. Meet Luther, a once-homeless man whose life was transformed by the support and belief of our farm. Over the years, we've provided Luther with odd jobs and a sense of purpose, helping him find his way out of homelessness.</p>
              <p>When you support Ophelia’s Blue Vine Farm, you're not just helping us grow fresh, organic produce; you're also helping to uplift members of our community.</p>
              <p><i>Watch the video that tells Luther's inspiring journey and learn more about the positive impact we're making together.</i></p>
            </Grid.Column>
            <Grid.Column width={12}>
            <iframe width="560" height="415" src="https://www.youtube.com/embed/tsueLsFjJgs?si=r7dosjtLUoE-MxZo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </Grid.Column>
            <Grid.Column width={12}>
              <p>As we continue to grow, we need your help to keep the farm thriving! We're seeking donations of tools, equipment, and supplies to support our mission:</p>
              <div className="list-container">
                <h3>Tools & Equipment Needed:</h3>
                <List 
                items={[
                  "Gardening Gloves",
                  "Hand Trowels",
                  "Pruning Shears",
                  "Watering Cans and Hoses",
                  "Rakes, Shovels, and Garden Hoes",
                  "Wheelbarrows",
                  "Compost Bins",
                  "Paint",
                  "Trailers",
                  "Seed Starting Trays and Pots",
                  "Small Tillers"
                ]} />
              </div>

              <div className="list-container">
                <h3>Larger Equipment Needed:</h3>
                <List 
                items={[
                  "Small Tractors & Tractor Implements",
                  "55-Gallon Drums",
                  "Storage Containers & Shipping Containers",
                  "Industrial Salvage",
                ]} />
              </div>

              <div className="list-container">
                <h3>Work Boots (All Sizes):</h3>
                <List 
                items={[
                  "Durable and comfortable boots for our hardworking team!",
                ]} />
              </div>

              <div className="list-container">
                <h3>Grills:</h3>
                <List 
                items={[
                  "Perfect for community events and preparing farm-fresh food on-site.",
                ]} />
              </div>

              <div className="list-container">
                <h3>Bottled Water</h3>
                <List 
                items={[
                  "",
                ]} />
              </div>

              <div className="list-container">
                <h3>Chicken Coop Supplies:</h3>
                <List 
                items={[
                  "Nesting Boxes",
                  "Feeders and Waterers",
                  "Chicken Feed and Grit",
                  "Bedding Materials (e.g., straw, hay)",
                  "Perches or Roosts",
                  "Coop Cleaning Supplies",
                  "Heat Lamps (for colder weather)",
                ]} />
              </div>

              <p>Every donation, big or small, makes a difference. Together, we can continue to grow healthy food and build a stronger community!</p>

              <p>To donate, please contact us via direct message or visit our farm at 2416 Vine St., Kansas City SATURDAYS 12-7PM. Thank you for your support! </p>

              <p className="hashtags"><strong>#UrbanFarming #CommunitySupport #OpheliasBlueVineFarm #HealthyLiving</strong></p>
              <Button size="large" primary as={Link} to="/contact/" >Contact Us</Button>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          address {
            street
            city
            state
            zip
          }
        }
      }
    }
    fishermanWebsitePage(title: { eq: "Donate Real Estate" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
