import AWS from "aws-sdk";

const emailTemplate = ({ name, email, message }) => ({
  Destination: {
    /* required */
    ToAddresses: ["mikebrollen@gmail.com"],
    BccAddresses: ["form-submissions@gofisherman.com"],
  },
  Message: {
    Body: {
      Text: {
        Charset: "UTF-8",
        Data: `
Someone has reached out to you via your website Donate Real Estate form.

Name: ${name}
Email: ${email}
Message: ${message}
`,
      },
    },
    Subject: {
      Charset: "UTF-8",
      Data: `[Ophelia's Blue Vine] Donate Real Estate Request`,
    },
  },
  Source: "Fisherman Websites <info@gofisherman.com>",
  ReplyToAddresses: [email],
});

const subscriptionCallback = (fields) =>
  new AWS.SES({ apiVersion: "2010-12-01" })
    .sendEmail(emailTemplate(fields))
    .promise();

export default subscriptionCallback;
